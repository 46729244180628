/*
0E0B16
A239CA
4717F6
E7DFDD
*/ 

* {
    margin: 0;
    color: #0E0B16; 
    font-family: Arial, sans-serif;
  }
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
.timer{
    background: linear-gradient(to left,#8110a0  0%,  #3beefe 100%);
    text-align: center;
    font-size: 200px; 
    width: 100%;
    background-size: cover;
    height: 100vh;
    /*position: fixed; */ 
}



.set-times{
  display: flex;
  flex-direction: column;
  float: left;
}
.set-time-but{
  
}
.time-inputs{
  font-size: medium;
  animation-name: stand-out;
  animation-duration: 1s;
}
@keyframes inputs-in {
  from {box-shadow: 0px 0px 0px 0px;}
  to {box-shadow: 1px 1px 4px 4px;}
}

.pauseButton:hover{
  box-shadow: 5px 5px;
}
.pauseButton:checked{
  box-shadow: 2px 2px;
}

  
li {
    float: left;
}
  
  li .brandLink {
    display: block;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    
    
  }

  @media (max-width: 420px){
    li .brandLink {
      width: min-content;
      
      
    }
  }

  li .brandLink:hover {
    box-shadow: 1px 1px 4px 4px;
    animation-name: stand-out;
    animation-duration: 1s;
  }
  
  @keyframes stand-out {
    from {box-shadow: 0px 0px 0px 0px;}
    to {box-shadow: 1px 1px 4px 4px;}
  }

  .new-work-sesh{
      text-align: center;
        padding-top: 10%;
        background: linear-gradient(to left,#8110a0  0%,  #3beefe 100%);
        background-size: cover;
        height: 100vh;
  }

  .startBut{
    text-align: center;
    width: 200px;
    height: 100px;
    margin: 30px;
    font-size: 30px;
    background-color: #A239CA;
  }

  .goodWork{
      text-align: center;
      font-size: large;
      background: linear-gradient(to left,#8110a0  0%,  #3beefe 100%);
      background-size: cover;
      height: 100vh;
  }
  .new-break{
      background: linear-gradient(to left,#8110a0  0%,  #3beefe 100%);
      background-size: cover;
      height: 100vh;
      text-align: center;
      font-size: large;
  }


  .breakBut{
    text-align: center;
    width: 200px;
    height: 100px;
    margin: 30px;
    font-size: 30px;
    background-color: #A239CA;
  }

  .bar-chart{
    width: 90%;
  }

  .login-textbox{
    width: max-content;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }
  .longin-btn{
    text-align: center;
    width: max-content;
    height: max-content;
    margin: 5px;
    font-size: 30px;
    background-color: #A239CA;
    position: relative;
    left: 50%;
    transform: translate(-50%);
  }
  .login-container{
    overflow:auto;
    width: max-content;
    position: relative;
    left: 50%;
    transform: translate(-50%, 50%);
    border-radius: 10%;
    border-style: solid;
    
  }

  .logout-btn{
    width: max-content;
    position: relative;
    left: 50%;
    transform: translate(-50%, 50%);
    font-size: 50px;
    background-color: blueviolet;
    border: #0E0B16;
    box-shadow: #000000;
    border-style: solid;
  }




  @keyframes open-card {
    from {transform: rotateX(90deg) translate(-50%, -50%);}
    to {transform: rotateX(0deg) translate(-50%, -50%);}
  }



  @media screen and (max-width: 681px){
    .timer{
      font-size: 100px;
    }
  }



/* inline-block ???  */
  #video-streams{
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    height: 90vh;
    width: 1400px;
    margin:0 auto;
}

.video-container{
    max-height: 100%;
    border: 2px solid black;
    background-color: #203A49;
    
}

.video-player{
    height: 100%;
    width: 100%;
}